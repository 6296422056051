import { DLGWDataLabTableRowCompat } from '@capital-markets-gateway/api-client-datalab-gateway';
import uniq from 'lodash/uniq';
import React from 'react';

import { CornerstoneInvestorTypeLabels } from '../../../../../types/domain/offering/constants';

export type Props = {
  cornerstone_investments: DLGWDataLabTableRowCompat['cornerstone_investments'];
};

export const CornerstoneType: React.FC<DLGWDataLabTableRowCompat> = ({
  cornerstone_investments,
}) => {
  const investmentTypes = uniq(
    (cornerstone_investments ?? []).map(investment =>
      investment.type ? CornerstoneInvestorTypeLabels[investment.type] : undefined
    )
  );
  return <div>{investmentTypes.length > 0 ? investmentTypes.join(' / ') : '-'}</div>;
};
