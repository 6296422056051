import { Box } from '@cmg/common';
import React, { useState } from 'react';

import Toggler from '../../../../shared/layout/components/Toggler';
import {
  heatmapBreakDownBy,
  heatmapPerformanceColumns,
  rubyHeatmapBreakDownBy,
} from '../../../constants-heatmap-columns';
import heatMapPerformanceButtons from '../../../model/buttons/heat-map-performance-buttons';
import HeatMap from '../../HeatMap';
import { SButtonBox } from '../shared/styled';

type Props = {
  chartData?: {
    items: {
      offer_to_1day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_3day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_7day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_14day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_30day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_90day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_180day: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_open: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      offer_to_current: {
        mean: number;
        median: number;
        weighted: number;
        count: number;
      };
      sector: string;
    }[];
    max_value: number;
    min_value: number;
  };
  useRuby?: boolean;
};

const WidgetPerformanceHeatMapBySector: React.FC<Props> = ({ chartData, useRuby }) => {
  const [heatMapPerformanceBtn, setHeatMapPerformanceBtn] = useState(heatMapPerformanceButtons[0]);

  const handlePerformanceBtnChange = btn => {
    setHeatMapPerformanceBtn(btn);
  };

  if (!chartData) {
    return null;
  }

  return (
    <div>
      <Box.Header title="Aftermarket Performance Heatmap" />

      <SButtonBox>
        <Toggler
          buttons={heatMapPerformanceButtons}
          activeButton={heatMapPerformanceBtn}
          onChange={handlePerformanceBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <HeatMap
          columns={heatmapPerformanceColumns}
          data={chartData}
          valueKey={heatMapPerformanceBtn.value}
          breakDownBy={useRuby ? rubyHeatmapBreakDownBy.SECTOR : heatmapBreakDownBy.SECTOR}
        />
      </Box.Content>
    </div>
  );
};

export default WidgetPerformanceHeatMapBySector;
