import { InternalAxiosRequestConfig } from 'axios';

import { getAppSettings } from '../../config/appSettings';

/**
 * Ruby Migration Feature Flag Interceptor
 */
const guidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
const getEndpointCompatFeatureFlag = (config: InternalAxiosRequestConfig) => {
  let url = config.url ?? '';
  url = url.replace(guidRegex, ':guid');

  if (url.endsWith('/') && url.length > 1) {
    url = url.slice(0, -1);
  }

  const queryIndex = url.indexOf('?');
  if (queryIndex >= 0) {
    url = url.substring(0, queryIndex);
  }

  if (!url.startsWith('/')) {
    url = '/' + url;
  }

  const method = config.method?.toUpperCase() ?? 'GET';
  return `${method}${url}`;
};

export const rubyMigrationRequestCompatInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const compatEndpoints = getAppSettings().features.rubyMigrationCompatEndpoints.split(';');
  if (compatEndpoints.indexOf(getEndpointCompatFeatureFlag(config)) >= 0) {
    config.baseURL = '/dlgw/api/v1/compat';
  } else {
    config.baseURL = '/dl/api/v1';
  }

  return config;
};
