import { exchangeMicLabels, typeUtil } from '@cmg/common';
import { Chip, Typography } from '@cmg/design-system';
import React from 'react';

import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import {
  getFormattedCurrencyInMillion,
  getSellingRestriction,
  getStatusChipColor,
  isUS,
} from '../../../../../shared/model/utils';
import CountryRenderer from '../../../../shared/components/country-renderer/CountryRenderer';
import type { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { getOfferPrice } from '../../shared/model/Shared.model';
import { type CardRowItem, emptyValue, OfferingProfileFieldName } from '../../shared/types';

export const useGetConvertSideBarData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const {
      pricingCurrency,
      attributes,
      typeDisplayName,
      finalTerm,
      status,
      statusDisplayName,
      securityTypeDisplayName,
      exchangeMic,
      exchangeCountryDisplayName,
      exchangeRegionDisplayName,
      exchangeCountry,
      convertibleAttributes,
      issuer,
    } = offeringProfile.offeringById ?? {};
    const { isSecRegistered, latestGrossProceedsTotal, isRule144A, isRegS } = attributes ?? {};
    const displaySymbol =
      isUS(offeringProfile) && !typeUtil.isDefined(convertibleAttributes?.underlyingSymbol)
        ? issuer?.primarySymbol
        : convertibleAttributes?.underlyingSymbol;

    const sidebarRows = [
      {
        name: OfferingProfileFieldName.OfferingType,
        value: typeDisplayName,
      },
      {
        name: OfferingProfileFieldName.OfferingStatus,
        value: status ? (
          <Chip
            label={<Typography variant="highlight2">{statusDisplayName}</Typography>}
            size="small"
            color={getStatusChipColor(status)}
          />
        ) : (
          emptyValue
        ),
      },
      {
        name: OfferingProfileFieldName.SecurityType,
        value: securityTypeDisplayName,
      },
      {
        name: OfferingProfileFieldName.Rank,
        value: convertibleAttributes?.rankDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.InstrumentDescription,
        value: convertibleAttributes?.securityNote ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.SellingRestrictions2,
        value: getSellingRestriction(isRegS, isRule144A, emptyValue),
      },
      {
        name: OfferingProfileFieldName.UnderlyingSymbol,
        value: !!displaySymbol ? displaySymbol.toUpperCase() : emptyValue,
      },
      {
        name: OfferingProfileFieldName.UnderlyingExchange,
        value:
          exchangeMic && exchangeMicLabels[exchangeMic]
            ? exchangeMicLabels[exchangeMic].shortName
            : exchangeMic,
      },
      {
        name: OfferingProfileFieldName.UnderlyingExchangeRegionCountry,
        value: (
          <CountryRenderer
            countryDisplayName={`${exchangeCountryDisplayName} / ${exchangeRegionDisplayName}`}
            countryCode={`${exchangeCountry}`}
          />
        ),
      },
      {
        name: OfferingProfileFieldName.SECRegidterd,
        value: <BooleanIcon value={isSecRegistered} />,
      },
      {
        name: OfferingProfileFieldName.PricingCurrency,
        value: pricingCurrency,
      },
      {
        name: OfferingProfileFieldName.GrossProceedsTotal,
        value: getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsTotal),
      },
      {
        name: OfferingProfileFieldName.OfferPrice,
        value: getOfferPrice(pricingCurrency, finalTerm),
      },
    ];
    return sidebarRows.filter(row => row.value !== null && row.value !== undefined);
  }, [offeringProfile]);
