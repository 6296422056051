import {
  Card,
  Divider,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetShareholdersData } from '../hooks/useGetShareholdersData';
import { cardHeight } from '../OfferingProfileCardItem';
import { StyledTableCell } from './ShareholdersWidget.styles';

const ShareholdersTable = ({
  shareholders,
}: {
  shareholders: ReturnType<typeof useGetShareholdersData>['shareholders'];
}) => {
  const isLargeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  return isLargeScreen ? (
    <TableContainer data-testid="ShareholdersTable-largeScreen">
      <Grid item minWidth={theme => theme.spacing(112)}>
        <Table size="medium">
          <TableHead>
            <TableRow color="text.secondary">
              <TableCell width="20%">Shareholder</TableCell>
              <TableCell width="12%">Holder Type</TableCell>
              <TableCell width="15%" align="right">
                Pre-Offering Base
              </TableCell>
              <TableCell width="5%" align="right">
                %
              </TableCell>
              <TableCell width="16%" align="right">
                Post-Offering Base
              </TableCell>
              <TableCell width="5%" align="right">
                %
              </TableCell>
              <TableCell width="22%" align="right">
                Post-Offering (Ovlt. Exercised)
              </TableCell>
              <TableCell width="5%" align="right">
                %
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shareholders.map(shareholder => (
              <TableRow key={shareholder.name}>
                <StyledTableCell>{shareholder.name}</StyledTableCell>
                <StyledTableCell>{shareholder.type}</StyledTableCell>
                <TableCell align="right">{shareholder.preOfferingShares}</TableCell>
                <TableCell align="right">{shareholder.preOfferingPct}</TableCell>
                <TableCell align="right">{shareholder.postOfferingShares}</TableCell>
                <TableCell align="right">{shareholder.postOfferingPct}</TableCell>
                <TableCell align="right">{shareholder.postOfferingOvlt}</TableCell>
                <TableCell align="right">{shareholder.postOfferingOvltPct}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </TableContainer>
  ) : (
    <Stack divider={<Divider />} data-testid="ShareholdersTable-smallScreen">
      {shareholders.map(shareholder => (
        <Stack paddingX={2} paddingY={1} key={shareholder.name}>
          <KeyValuePair minWidth="large" label="Shareholder" value={shareholder.name} />
          <KeyValuePair minWidth="large" label="Holder Type" value={shareholder.type} />
          <KeyValuePair
            minWidth="large"
            label="Pre-Offering Shares"
            value={shareholder.preOfferingShares}
          />
          <KeyValuePair
            minWidth="large"
            label="Pre-Offering %"
            value={shareholder.preOfferingPct}
          />
          <KeyValuePair
            minWidth="large"
            label="Post-Offering Shares"
            value={shareholder.postOfferingShares}
          />
          <KeyValuePair
            minWidth="large"
            label="Post-Offering %"
            value={shareholder.postOfferingPct}
          />
          <KeyValuePair
            minWidth="large"
            label="Post-Offering (Ovlt. Exercised)"
            value={shareholder.postOfferingOvlt}
          />
          <KeyValuePair
            minWidth="large"
            label="Post-Offering (Ovlt. Exercised) %"
            value={shareholder.postOfferingOvltPct}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const ShareholdersWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { shareholders } = useGetShareholdersData(offeringProfile);
  const title = 'Shareholders & Investors';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!shareholders.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <ShareholdersTable shareholders={shareholders} />
          )}
        </Section>
      </Card>
    </Grid>
  );
};

export default ShareholdersWidget;
