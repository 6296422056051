import { apiTypes, urlUtil } from '@cmg/common';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { compile } from 'path-to-regexp';

import { ApiResponse } from '../../types/api/ApiResponse';
import { UUID } from '../../types/common';
import { FILE_MIME_TYPES } from './MimeTypes';
import { normalizeErrorResponse, normalizeSuccessResponse } from './normalize-response';

/**
 * UTILS
 */
export function makeFetchListRequest<
  Dto,
  Query extends { [key: string]: any },
  Parameters extends {} = {}
>(apiClient: AxiosInstance, route: string) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (query: Query, parameters: Parameters = {} as Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    const queryString = urlUtil.queryStringify(query, { arrayFormat: 'none' });
    return apiClient.get<Dto, ApiResponse<Dto>>(`${url}${queryString}`);
  };
}

export function makeFetchEntityRequest<Dto, Parameters extends {} = { id: UUID }>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.get<Dto, ApiResponse<Dto>>(url);
  };
}

export function makeParameterlessFetchEntityRequest<Dto>(apiClient: AxiosInstance, route: string) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile(route);

  return () => {
    // creating a concrete url based on provided parameters
    const url = toUrl();
    return apiClient.get<Dto, ApiResponse<Dto>>(url);
  };
}

export function makeParameterlessUpdateEntityRequest<RequestDto, ResponseDto>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile(route);

  return (data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl();
    return apiClient.put<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeParameterlessCreateEntityRequest<RequestDto, ResponseDto>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile(route);

  return (data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl();
    return apiClient.post<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeCreateEntityRequest<RequestDto, ResponseDto, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters, data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.post<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeUpdateEntityRequest<
  RequestDto,
  ResponseDto,
  Parameters extends {} = { id: UUID }
>(apiClient: AxiosInstance, route: string) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters, data: RequestDto) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.put<RequestDto, ApiResponse<ResponseDto>>(url, data);
  };
}

export function makeDeleteEntityRequest<Parameters extends { id: UUID } = { id: UUID }>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.delete<undefined, ApiResponse<undefined>>(url);
  };
}

export function makeDeleteEntityRequestWithResponse<Dto, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  route: string
) {
  // extracts route parameters into an url string factory based on parameters
  const toUrl = compile<Parameters>(route);

  return (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = toUrl(parameters);
    return apiClient.delete<Dto, ApiResponse<Dto>>(url);
  };
}

export function makePostDownloadRequest<RequestDto, Parameters extends object = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (data: RequestDto, parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);

    try {
      const response = await apiClient.post<RequestDto, AxiosResponse<Blob>>(url, data, {
        responseType: 'blob',
        headers: { Accept: FILE_MIME_TYPES.XLSX },
      });

      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(
        error as AxiosError<{ error?: apiTypes.GenericServerError } | undefined | null>
      );
    }
  };
}
