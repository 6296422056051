import {
  Button,
  Card,
  Grid,
  InfoOutlinedIcon,
  RegularBreakpoints,
  Section,
  TextField,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { cardHeight } from '../OfferingProfileCardItem';

export type Props = {
  onSaveUserNote: (userNote: string | null) => void;
  userNote?: string | null;
  cardSizes?: RegularBreakpoints;
  id?: string;
  isSaving: boolean;
};

const UserNotesWidget: React.FC<Props> = ({
  userNote,
  onSaveUserNote,
  cardSizes,
  id,
  isSaving,
}) => {
  const title = 'Private Notes';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: userNote ?? '',
    },
    onSubmit: value => {
      onSaveUserNote(value?.note);
    },
  });
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section
          title={title}
          titleItems={
            <Tooltip
              title={
                <Grid maxWidth={theme => theme.spacing(19.75)}>
                  <Typography variant="tooltip">
                    Notes are private to you and not visible to anyone else
                  </Typography>
                </Grid>
              }
              placement="top-start"
              variant="info"
            >
              <Typography color="text.secondary" paddingLeft={1}>
                <InfoOutlinedIcon fontSize="small" />
              </Typography>
            </Tooltip>
          }
          actions={[
            <Button
              key="save-button"
              variant="contained"
              color="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={!formik.dirty || isSaving}
            >
              <Typography variant="highlight1">Save</Typography>
            </Button>,
          ]}
          aria-label={title}
          paddingTop={theme => theme.spacing(3)}
        >
          <FormikProvider value={formik}>
            <Form>
              <TextField
                label="Notes"
                name="note"
                multiline
                rows={6}
                value={formik.values.note}
                onChange={formik.handleChange}
              />
            </Form>
          </FormikProvider>
        </Section>
      </Card>
    </Grid>
  );
};

export default UserNotesWidget;
