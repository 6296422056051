import { AxiosResponse } from 'axios';

import { encodeQueryCamelToSnake } from '../../../../common/helpers/url-helpers';
import { ApiResponse } from '../../../../types/api/ApiResponse';
import {
  UserReport,
  UserReportCreate,
  UserReportShare,
  UserReportUpdate,
} from '../../../../types/domain/report/userReport';
import {
  UserReportPartialsFetch,
  UserReportPartialsResult,
} from '../../../../types/domain/report/userReportPartial';
import { dlgwApiClient } from '../../apiClient';
import { snakeCaseToCamelCaseAxiosConfig } from '../snakeCaseToCamelCaseAxiosConfig';

const USER_REPORTS = 'datalab_api_call/USER_REPORTS';

export const listUserReports = ({
  searchQuery,
  favorites,
  paginationParams,
}: UserReportPartialsFetch) => {
  const searchQueryPart = searchQuery ? { query: searchQuery } : {};
  const favoritesPart = favorites ? { favorites } : {};
  const query = { ...paginationParams, ...searchQueryPart, ...favoritesPart };

  return dlgwApiClient.get<UserReportPartialsResult, ApiResponse<UserReportPartialsResult>>(
    `/compat/user_reports?${encodeQueryCamelToSnake(query)}`,
    {
      ...snakeCaseToCamelCaseAxiosConfig,
      // @ts-ignore
      apiCallId: USER_REPORTS,
    }
  );
};

export const fetchUserReport = (id: string) =>
  dlgwApiClient.get(`/compat/user_reports/${id}`, snakeCaseToCamelCaseAxiosConfig);

export const createUserReport = (data: UserReportCreate) =>
  dlgwApiClient.post<UserReportCreate, AxiosResponse<UserReport>>(
    `/compat/user_reports`,
    data,
    snakeCaseToCamelCaseAxiosConfig
  );

export const updateUserReport = (id: string, data: UserReportUpdate) =>
  dlgwApiClient.put<UserReportUpdate, AxiosResponse<UserReport>>(
    `/compat/user_reports/${id}`,
    data,
    snakeCaseToCamelCaseAxiosConfig
  );

export const deleteUserReport = (id: string) =>
  dlgwApiClient.delete(`/compat/user_reports/${id}`, snakeCaseToCamelCaseAxiosConfig);

export const shareUserReport = (reportId: string, data: UserReportShare) =>
  dlgwApiClient.post<UserReportShare, AxiosResponse<UserReport>>(
    `/compat/user_reports/${reportId}/share`,
    data,
    snakeCaseToCamelCaseAxiosConfig
  );

export const favoriteUserReport = (id: string, isFavorite: boolean) =>
  dlgwApiClient.put<never, AxiosResponse<UserReport>>(
    `/compat/user_reports/${id}/favorite?is_favorite=${isFavorite}`,
    snakeCaseToCamelCaseAxiosConfig
  );
