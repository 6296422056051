import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';

import { objectCamelToSnake, objectSnakeToCamel } from '../../../common/helpers/url-helpers';

const toSnakeCaseRequest = data => {
  if (data) {
    const dataObj = JSON.parse(data);

    return typeof dataObj === 'object' ? JSON.stringify(objectCamelToSnake(dataObj)) : data;
  }

  return data;
};

const fromSnakeCaseResponse = data => {
  if (data && typeof data === 'object') {
    return objectSnakeToCamel(data);
  }

  return data;
};

export const snakeCaseToCamelCaseAxiosConfig = {
  transformRequest: [
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    toSnakeCaseRequest,
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    fromSnakeCaseResponse,
  ],
};
