export const enumValueToEnumKey = <T extends { [index: string]: string }>(
  enumDef: T,
  enumValue: string
): keyof T => {
  let keys = Object.keys(enumDef).filter(x => enumDef[x] === enumValue);
  if (keys.length !== 1) {
    throw new Error(`Invalid enum value` + enumValue);
  }

  return keys[0] as keyof T;
};
